import React, { Component } from 'react';
import BtnTicket from './btn/ticket';
import '../css/title_block.css';

class TitleBlock extends Component {
  splittedSubTitle = () => {
    if (this.props.sub_title) {
      return this.props.sub_title.split('+').join('<wbr>+');
    } else {
      return '';
    }
  };

  splittedTitle = () => {
    if (this.props.title) {
      return this.props.title.split('+').join('<wbr>+');
    } else {
      return '...';
    }
  };

  render() {
    return (
      <div className="row TitleBlockWrapper">
        <div className="TitleBlock">
          {this.props.ticket_url && <BtnTicket ticket_url={this.props.ticket_url} />}
          <div className="MainTitle" dangerouslySetInnerHTML={{ __html: this.splittedTitle() }} />
          {this.props.sub_title && (
            <div
              className="SubTitle"
              dangerouslySetInnerHTML={{ __html: this.splittedSubTitle() }}
            />
          )}
        </div>
      </div>
    );
  }
}
export default TitleBlock;
