import React from 'react';
import PropTypes from 'prop-types';
import Input from './Input';

const Number = props => {
  return <Input type="number" {...props} />;
};

Number.propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  // getValidationState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string
};

export default Number;
