import React from 'react';

export const SvgFilters = props => {
  return (
    <svg className="defs-only">
      <filter
        id="teal_half"
        x="50%"
        y="0%"
        filterUnits="objectBoundingBox"
        height="100%"
        width="50%"
        colorInterpolationFilters="sRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".2 0    0    0 0
                  0  .749 0    0 0
                  0  0    .796 0 0
                  0  0    0    1 0
        "
        />
      </filter>
      <filter
        id="black_half"
        x="50%"
        y="0%"
        filterUnits="objectBoundingBox"
        primitiveUnits="objectBoundingBox"
        height="100%"
        width="50%"
        colorInterpolationFilters="sRGB"
        filterRes="1"
      >
        <feColorMatrix
          type="matrix"
          values=".5 0  0  0 0
                  0  .5 0  0 0
                  0  0  .5 0 0
                  0  0  0  1 0
        "
        />
      </filter>
      <filter
        id="teal_full"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
        height="100%"
        width="100%"
        colorInterpolationFilters="sRGB"
      >
        <feColorMatrix
          type="matrix"
          values=".2 0    0    0 0
                  0  .749 0    0 0
                  0  0    .796 0 0
                  0  0    0    1 0
        "
        />
      </filter>
      <filter
        id="yellow_full"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
        height="100%"
        width="100%"
        colorInterpolationFilters="sRGB"
      >
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 0
                  0 1 0 0 0
                  0 0 0 0 0
                  0 0 0 1 0
        "
        />
      </filter>
      <filter
        id="purple_full"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
        height="100%"
        width="100%"
        colorInterpolationFilters="sRGB"
      >
        <feColorMatrix
          type="matrix"
          values="1 0 0 0 0
                  0 0 0 0 0
                  0 0 1 0 0
                  0 0 0 1 0
        "
        />
      </filter>
      <filter
        id="green_full"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
        height="100%"
        width="100%"
        colorInterpolationFilters="sRGB"
      >
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0
                  0 1 0 0 0
                  0 0 0 0 0
                  0 0 0 1 0
        "
        />
      </filter>
    </svg>
  );
};
