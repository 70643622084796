import React from 'react';
import Btn from './btn/Btn';
import BtnTicket from './btn/ticket';
import BtnJustIn from './btn/just_in';
import EventLink from '../helpers/EventLink';
import DateBlock from './DateBlock';
import '../css/double_carousel_item.css';

function DoubleCarouselItem(props) {
  let event = props.item;
  let divStyle = {
    backgroundImage: 'url(' + event.main_image_sizes.image_big + ')',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
  return (
    <div className={'DoubleCarouselItem hidden-xs col-sm-6 col-md-4 ' + props.pos + " " + event.venue_color}>
      <EventLink event={event}>
        <div className="row no-gutter">
          <div className="col-xs-12 wrapper_1_2" style={divStyle}>
            <BtnJustIn event={event} />
            <img
              className="date_overlay"
              width="100%"
              src={event.main_image_sizes.image_big}
              alt=""
            />
            <DateBlock event={event} />
          </div>
        </div>
        <div className="row no-gutter opaque-bg-color">
          {(event.ticket_url || event.free_event) ? (
            <BtnTicket event={event} noFly={true} />
          ) : (
            <Btn />
          )}
          <div className="title" dangerouslySetInnerHTML={{ __html: event.title }} />
        </div>
      </EventLink>
    </div>
  );
}

export default DoubleCarouselItem;
