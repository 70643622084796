import 'whatwg-fetch'; // To support this in Rails

export const decoratedFetch = (path, options) => {
  var domain = process.env.REACT_APP_API_HOST || 'https://burgerweeshuis.nl';
  var api_path = '/api/v2';
  path = path.replace(api_path, '');
  return fetch(domain + api_path + path, options).then(response => {
    if (!response.ok) {
      throw Error(response.status + ' ' + response.statusText);
    }
    return response;
  });
};
