import React, { Component } from 'react';
import Spinner from './Spinner';
import LadderRow from './LadderRow';
import LadderBlocks from './LadderBlocks';
import { decoratedFetch } from './../helpers/fetch';
import LoadMore from './btn/LoadMore';
import PageHeader from './PageHeader';
import { SearchFilterOptionsContext } from './SearchFilterOptionsContext';

var flat = require('array.prototype.flat');

class Ladder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      agenda_events: props.agendaEvents || [],
      filtered_events: props.agendaEvents || [],
      past_events: props.past_events || [],
      past_events_loaded: false,
      expanded: sessionStorage.getItem('expanded') === 'true',
      filter: props.filter || null,
      search_value: props.searchValue || '',
      forceLadder: props.forceLadder,
      albums: props.albums || false,
      loading: false,
      loading_past: false
    };
  }

  componentDidMount() {
    this.setState(
      {
        loading: false
      },
      this._retrieveEvents
    );
  }

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.state.albums !== nextProps.albums) {
      this.setState(
        {
          agenda_events: nextProps.agendaEvents || [],
          past_events: [],
          albums: nextProps.albums
        },
        this._retrieveEvents
      );
    }
    if (nextProps.searchValue !== this.state.search_value) {
      this.setState({
        past_events: [],
        past_events_loaded: false
      });
    }
    if (
      nextProps.filter !== this.state.filter ||
      nextProps.searchValue !== this.state.search_value ||
      nextProps.forceLadder !== this.state.forceLadder
    ) {
      this.setState(
        {
          filter: nextProps.filter,
          search_value: nextProps.searchValue,
          forceLadder: nextProps.forceLadder
        },
        this.filterEvents
      );
    }
  };

  _retrieveEvents = () => {
    if (this.state.agenda_events.length === 0) {
      const key = 'events';
      const cachedEvents = localStorage.getItem(key);
      if (cachedEvents) {
        this.setState(
          { agenda_events: JSON.parse(cachedEvents), loading: false },
          this.filterEvents
        );
      } else {
        this.setState({ loading: true });
      }
      decoratedFetch('/events.json?category=' + (this.props.tag || ''))
        .then(response => response.json())
        .then(data => {
          let new_filters = [[null, 'ALLES']];
          if (data.target_audience) {
            if (typeof Set !== 'undefined') {
              // safe to use Set function
              let present_tags = new Set(
                flat(data.events.map(event => event.target_audience_list))
              );
              this.context.updateAvailableFilters(
                new_filters.concat(
                  data.target_audience.filter(targets => present_tags.has(targets[0]))
                )
              );
            } else {
              // Fallback to predefined list
              this.context.updateAvailableFilters(new_filters.concat(data.target_audience));
            }
          }
          this.setState({ agenda_events: data.events, loading: false }, this.filterEvents);
          localStorage.setItem(key, JSON.stringify(data.events));
        });
    }
  };

  filterEvents = () => {
    let filtered_events = this.state.agenda_events;

    if (this.props.filter) {
      filtered_events = filtered_events.filter(e =>
        e.target_audience_list.includes(this.props.filter)
      );
    }
    if (
      this.props.searchValue !== '' &&
      this.props.searchValue !== null &&
      this.props.searchValue !== undefined
    ) {
      filtered_events = filtered_events.filter(
        e =>
          e.title.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1 ||
          e.sub_title.toLowerCase().indexOf(this.props.searchValue.toLowerCase()) !== -1
      );
    }
    this.setState({ filtered_events: filtered_events });
  };

  handleClick = () => {
    sessionStorage.setItem('expanded', !this.state.expanded);
    this.setState({ expanded: !this.state.expanded });
  };

  handleFindMoreClick = () => {
    this.setState({ loading_past: true });
    decoratedFetch('/events/past.json?q=' + this.state.search_value)
      .then(response => response.json())
      .then(data =>
        this.setState({ past_events: data.events, past_events_loaded: true, loading_past: false })
      );
  };

  render() {
    let filtered_events = this.state.filtered_events;

    let rowOrBlock = this.props.displayLadder || this.state.forceLadder;

    if (this.state.loading) {
      return (
        <div id="ladder">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div id="ladder">
          {this.constructor.name === 'DinnerLadder' && filtered_events.length === 0 && (
            <div className="col-xs-12 text-center">
              <h2>Op dit moment is er nog geen Burgercafé ingepland.</h2>
            </div>
          )}
          {rowOrBlock ? (
            filtered_events.map((item, idx) => (
              <LadderRow item={item} key={item.id} idx={idx} albums={this.state.albums} />
            ))
          ) : (
            <LadderBlocks events={filtered_events} albums={this.state.albums} />
          )}
          {!this.props.tag &&
            this.state.search_value &&
            this.state.filtered_events.length === 0 &&
            this.state.past_events.length === 0 && (
              <div className="text-center inverted-bg-color">
                <br />
                <strong>
                  NIETS GEVONDEN VOOR &quot;<em>{this.state.search_value}&quot;</em>
                </strong>
                <br />
                <br />
              </div>
            )}
          {!this.props.tag && this.state.search_value && (
            <div className="text-center primary-bg-color">
              <br />
              <button className="btn btn-default" onClick={this.handleFindMoreClick}>
                Zoek in het verleden
              </button>
              <br />
              <br />
            </div>
          )}
          {this.state.loading_past && (
            <React.Fragment>
              <Spinner />
            </React.Fragment>
          )}
          {this.state.past_events_loaded ? (
            this.state.past_events.length > 0 ? (
              <React.Fragment>
                <PageHeader>IN HET VERLEDEN</PageHeader>
                {rowOrBlock ? (
                  this.state.past_events.map((item, idx) => (
                    <LadderRow item={item} key={item.id} idx={idx} />
                  ))
                ) : (
                  <LadderBlocks events={this.state.past_events} />
                )}
              </React.Fragment>
            ) : (
              <div className="text-center inverted-bg-color">
                <br />
                <h2>
                  NIETS GEVONDEN IN HET VERLEDEN VOOR <em>{this.state.search_value}</em>
                </h2>
                <br />
                <br />
              </div>
            )
          ) : (
            <div />
          )}
        </div>
      );
    }
  }
}

Ladder.contextType = SearchFilterOptionsContext;
export default Ladder;
