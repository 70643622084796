import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormGroup, FormControl, HelpBlock, Radio } from 'react-bootstrap';

const RadioGroup = props => {
  return (
    <fieldset>
      {props.label && <legend>{props.label}</legend>}
      <FormGroup
        controlId={props.controlId}
        onChange={props.handleChange}
        // validationState={this.props.getValidationState}
      >
        {props.children.map((child, idx) => (
          <Radio
            key={idx}
            name={props.controlId}
            value={child.value}
            checked={String(props.value) === String(child.value)}
            onChange={props.handleChange}
          >
            {child.label}
          </Radio>
        ))}
        <FormControl.Feedback />
        {props.help && <HelpBlock>{props.help}</HelpBlock>}
      </FormGroup>
    </fieldset>
  );
};

RadioGroup.propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  // getValidationState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string
};

export default RadioGroup;
