import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormControl, FormGroup, ControlLabel, HelpBlock } from 'react-bootstrap';
import Checkbox from './Checkbox';

export default class CheckboxGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      form: [],
      values: {}
    };
  }

  handleChange = event => {
    // event = new CustomEvent('build', {target});
    this.props.handleChange(event);
  };
  render() {
    return (
      <fieldset>
        {this.props.label && <legend>{this.props.label}</legend>}
        <FormGroup
          name={this.props.controlId}
          // controlId={this.props.controlId}
          // onChange={this.props.handleChange}
          // validationState={this.props.getValidationState}
        >
          {this.props.children.map((child, idx) => (
            <Checkbox
              key={idx}
              controlId={child.name}
              title={child.name}
              // inputRef={ref => { this.input = ref; }}
              value={child.value}
              checked={this.props.checkedValues[child.controlId]}
              handleChange={this.handleChange}
            >
              {child.label}
            </Checkbox>
          ))}
          <FormControl.Feedback />
          {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>}
        </FormGroup>
      </fieldset>

      // <FormGroup
      //   controlId={this.props.controlId}
      //   // validationState={this.this.props.getValidationState}
      // >
      //   {this.props.label && <ControlLabel>{this.props.label}</ControlLabel>}
      //   <Checkbox
      //     name={this.props.controlId}
      //     value={this.props.value}
      //     onChange={this.props.handleChange}
      //   >
      //     {this.props.children}
      //   </Checkbox>
      //   {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>}
      // </FormGroup>
    );
  }
}

CheckboxGroup.propTypes = {
  // controlId: PropTypes.string.isRequired,
  // value: PropTypes.string.isRequired,
  // label: PropTypes.string,
  // handleChange: PropTypes.func.isRequired,
  // // getValidationState: PropTypes.func.isRequired,
  // placeholder: PropTypes.string,
  // help: PropTypes.string,
};
