import React, { Component } from 'react';
import PageHeader from './PageHeader';
import '../css/mailing_list.css';

class MailingList extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="container-fluid opaque-bg-color MailingList non-sticky">
        <PageHeader>BLIJF OP DE HOOGTE</PageHeader>
        <div className="container">
          <p className="text-center">
            Ja! Stuur het programma van het Burgerweeshuis wekelijks naar het volgende e-mailadres:
          </p>
          <form
            action="https://burgerweeshuis.us6.list-manage.com/subscribe/post?u=84cc2a3bf6ed5d43926339820&amp;id=0575798ed9"
            method="post"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            className="validate"
            target="_blank"
            noValidate
          >
            <div id="mce-responses">
              <div className="response" id="mce-error-response" style={{ display: 'none' }} />
              <div className="response" id="mce-success-response" style={{ display: 'none' }} />
            </div>
            <div style={{ position: 'absolute', left: '-5000px' }} aria-hidden="true">
              <input
                readOnly
                type="text"
                name="b_84cc2a3bf6ed5d43926339820_0575798ed9"
                tabIndex="-1"
                value=""
              />
            </div>

            <div className="hidden-xs col-sm-2" />
            <div className="col-xs-8 col-sm-6">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                name="EMAIL"
                id="mce-EMAIL"
              />
            </div>
            <button
              type="submit"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
              className="btn btn-primary col-xs-4  col-sm-2"
            >
              Verzend
            </button>
          </form>
        </div>
      </div>
    );
  }
}

export default MailingList;
