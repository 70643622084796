import Ladder from './Ladder';
import { decoratedFetch } from './../helpers/fetch';

export default class DinnerLadder extends Ladder {
  componentDidMount() {
    if (this.state.agenda_events.length === 0) {
      decoratedFetch('/dinners.json')
        .then(response => response.json())
        .then(data => this.setState({ agenda_events: data.events }, this.filterEvents));
    }
  }
}
