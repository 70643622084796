import React from 'react';
import Heroes from '../components/Heroes';
import PageHeader from '../components/PageHeader';
import News from '../components/News';
import Footer from '../components/Footer';
import HeaderTags from '../helpers/HeaderTags';
import '../css/news_page.css';

export default function NewsPage(props) {
  let news_item_id = props.match.params.newsId;
  return (
    <div>
      <div className="inverted-bg-color" id="NewsPage">
        <Heroes />
        <div className="container-fluid NewsPageContent">
          <div className="container">
            <PageHeader>NIEUWS</PageHeader>
            <News loadAmount="8" tag={props.tag} newsItemId={news_item_id} />
          </div>
        </div>
      </div>
      <Footer />
      <HeaderTags title="NIEUWS" />
    </div>
  );
}
