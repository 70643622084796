import React from 'react';
import '../css/event_detail.css';

function EventDetail(props) {
  let details = props.details || [];
  let alternating_class_name = 'col-xs-12 col-sm-3';
  if (props.className) {
    alternating_class_name = props.className;
  }
  return (
    <div className={'EventDetail ' + alternating_class_name}>
      {details.map((item, idx) => (
        <dl key={idx}>
          <dt className="col-xs-6 col-sm-12">{item[0]}:</dt>
          <dd className="col-xs-6 col-sm-12" dangerouslySetInnerHTML={{ __html: item[1] }} />
        </dl>
      ))}
    </div>
  );
}

export default EventDetail;
