import React from 'react';
import PropTypes from 'prop-types';

import EventLink from '../helpers/EventLink';
import DateBlock from './DateBlock';
import TitleBlock from './TitleBlock';
import BtnTicket from './btn/ticket';
import '../css/hero.css';

function Hero(props) {
  let event = props.event;
  let show_title = true;
  let show_dash = true;
  if (typeof props.showTitle !== 'undefined') {
    show_title = props.showTitle;
  }
  if (typeof props.showDash !== 'undefined') {
    show_dash = props.showDash;
  }
  if (event.venue === 'Burgerhaven') {
    show_dash = false;
  }
  let hero_image = {
    backgroundImage: 'url(' + event.main_image_sizes.image_landing_big + ')'
  };

  return (
    <div className={"container Hero " + event.venue_color} style={hero_image}>
      <EventLink event={event}>
        <div className={'container HeroBrush ' + (show_dash ? 'active' : '')}>
          <div className="container DateTitleWrapper">
            {event.ticket_url && (
              <div className={'hidden-xs hero_ticket_link ' + (props.fixed ? 'fixed' : '')}>
                <BtnTicket event={event} noFly={true} rotate90={true} sticky={true} />
              </div>
            )}
            {show_title && event.date && (
              <div className="row no-gutter">
                {'News' !== event.type &&
                  <div className="col-xs-2">
                    <DateBlock event={event} show_border={true} />
                  </div>
                }
                <div className="col-xs-10 title">
                  <TitleBlock title={event.title} sub_title={event.sub_title} />
                </div>
              </div>
            )}
          </div>
        </div>
      </EventLink>
    </div>
  );
}
Hero.propTypes = {
  event: PropTypes.shape({
    main_image_sizes: PropTypes.shape({
      image_landing_big: PropTypes.string.isRequired
    }).isRequired,
    title: PropTypes.string.isRequired
  }).isRequired
};

export default Hero;
