import React from 'react';

import EventPage from './EventPage';

function PreviewPage(props) {
  return (
    <EventPage
      ref={EventPage => {
        window.EventPage = EventPage;
      }}
      {...props}
    />
  );
}

export default PreviewPage;
