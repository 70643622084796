import React from 'react';

export const SearchFilterOptionsContext = React.createContext({
  filters: [
    // ['ALLES', null],
    // ['CONCERT', 'concert'],
    // ['DANCE', 'dance'],
    // ['CAFÉ', 'cafe'],
    // ['DIVERS', 'various']
    ['POP', 'Pop'],
    ['ROUTE 77', 'Route77'],
    ['BURGER HARD', 'BurgerHard'],
    ['CLASSICS', 'Classics'],
    ['NACHTBURGER', 'NachtBurger'],
    ['URBAN', 'Urban'],
    ['EVENTS', 'Events'],
    ['TALENT', 'Talent'],
  ],
  updateAvailableFilters: () => {}
});
