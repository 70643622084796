import React from 'react';
import '../css/helpers/grid_helper.css';

const GridHelper = () => (
  <div className="container GridHelperContainer GridHelper">
    <div className="row">
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
      <div className="col-xs-1"> </div>
    </div>
  </div>
);

export default GridHelper;
