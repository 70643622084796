import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import { decoratedFetch } from './../../helpers/fetch';

import Input from './Input';
import Email from './Email';
import Number from './Number';
import TextArea from './TextArea';
import Checkbox from './Checkbox';
import CheckboxGroup from './CheckboxGroup';
import RadioGroup from './RadioGroup';

import '../../css/form.css';

export default class Form extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      data: {
        form: []
      },
      values: {},
      formValid: true,
      status: null
    };
  }

  componentDidMount() {
    if (this.state.data.form.length === 0) {
      this.setState({ loading: true });
      decoratedFetch('/forms.json?name=' + this.props.name)
        .then(response => response.json())
        .then(data => this.setState({ data, loading: false }));
    }
  }

  doSubmit = () => {
    decoratedFetch('/forms', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ values: this.state.values, name: this.state.data.name })
    })
      .then(response => response.json())
      .then(data => this.setState(data, this.handleResponse));
  };
  formValid = () => {
    // console.table(this.state);
    return true;
    // console.table(this.state.values)
    // const values = this.state.values
    // const fields = this.state.data.form.map(item => item.name)
    // var validAttribs = fields.filter((attrib) => {
    //   return values[attrib].length < 3
    // })
    // var isValid = validAttribs.length === 0
    // // this.setState({formValid: isValid && !!email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)}, this.logState)
    // this.setState({formValid: isValid}, this.logState)
  };
  handleChange = event => {
    const target = event.target;
    const name = target.name;
    var value = target.type === 'checkbox' ? target.checked : target.value;
    this.setState(
      {
        values: {
          ...this.state.values,
          [name]: value
        }
      },
      this.formValid
    );
  };

  handleResponse = () => {
    // console.table(this.state);
  };

  handleSubmit = event => {
    event.preventDefault();
    if (this.state.formValid) {
      this.setState({ submitting: true }, this.doSubmit);
    } else {
      alert('Niet alle gegevens kloppen');
    }
  };

  render() {
    if (this.state.loading) {
      return <div>FORMULIER</div>;
    } else if (this.state.status) {
      return (
        <div className="form well inverted-bg-color mail_send">
          <h3>{this.state.data.title}</h3>
          <div className="lead ">
            {'mail sent' === this.state.status && (
              <span>Uw email is verzonden! U hoort spoedig van ons.</span>
            )}
          </div>
        </div>
      );
    } else {
      // console.table(this.state.data.form)
      return (
        <form
          onSubmit={this.handleSubmit}
          className="form well inverted-bg-color new_membership"
          id="new_membership"
          acceptCharset="UTF-8"
          method="post"
        >
          <h3>{this.state.data.title}</h3>
          <input name="utf8" type="hidden" value="✓" />
          {this.state.data.form.map((item, idx) => {
            let values = {
              controlId: item.name,
              label: item.label,
              value: this.state.values[item.name] || '',
              handleChange: this.handleChange,
              placeholder: item.placeholder,
              key: idx,
              required: item.required
            };
            if (item.type === 'input') {
              return <Input {...values} />;
            } else if (item.type === 'email') {
              return <Email {...values} />;
            } else if (item.type === 'number') {
              return <Number {...values} />;
            } else if (item.type === 'textarea') {
              return <TextArea {...values} />;
            } else if (item.type === 'checkbox') {
              return (
                <Checkbox {...values} checked={this.state.values[item.name]} label="">
                  {item.label}
                </Checkbox>
              );
            } else if (item.type === 'checkbox-group') {
              return (
                <CheckboxGroup {...values} label={item.label} checkedValues={this.state.values}>
                  {item.elements}
                </CheckboxGroup>
              );
            } else if (item.type === 'radio-group') {
              return <RadioGroup {...values}>{item.elements}</RadioGroup>;
            } else {
              // console.log(item.type);
              return <div key={idx}>{item.type}</div>;
            }
          })}
          <Button type="submit" bsStyle="primary" disabled={this.state.submitting}>
            {this.state.submitting ? <span>Bezig met verzenden</span> : <span>Verzend</span>}
          </Button>
        </form>
      );
    }
  }

  // constructor(props) {
  //   super(props);
  //   let form = {}
  //   let fields = []
  //   console.log(props.children);
  //   props.children.forEach((node, index) => {
  //     if (node.type === 'tag') {
  //       fields.push(node.attribs.name)
  //       form[node.attribs.name] = ""
  //     }
  //   })
  //   this.state = {
  //     form: form,
  //     fields: fields,
  //     formValid: false,
  //     submitting: false
  //   }
  // }
  //
  // onCapthaChange = (value) => {
  //   this.setState({
  //     form: {
  //       ...this.state.data.form,
  //       captcha: value || ''
  //     }
  //   }, this.formValid)
  // }
  //
  // doSubmit = () => {
  //   decoratedFetch('/forms', {
  //     method: 'POST',
  //     headers: {
  //       'Accept': 'application/json',
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify(this.state)
  //   })
  //   .then( response => response.json() )
  //   .then( data => this.setState(data, this.handleResponse))
  // }
  // logState = () => {
  //   if (window.console) {
  //     console.log(this.state.formValid, this.state.submitting, this.state.form);
  //   }
  // }
  //
  // render() {
  //   const form = this.state.data.form
  //   return (
  //     <form onSubmit={this.handleSubmit} className="form" id="form" acceptCharset="UTF-8" method="post">
  //       {this.props.children.map((node, index) => {
  //         if (node.type === 'tag') {
  //           var values = {
  //             controlId: node.attribs.name,
  //             label: node.attribs.label,
  //             value: form[node.attribs.name],
  //             handleChange: this.handleChange,
  //             placeholder: node.attribs.placeholder,
  //             key: index
  //           }
  //           if (node.name === 'input') {
  //             return <Input {...values} />
  //           } else if (node.name === 'email') {
  //             return <Email {...values} />
  //           } else if (node.name === 'number') {
  //             return <Number {...values} />
  //           // } else {
  //           //   // console.log(node.name);
  //           //   return <div key={index}>{node.name}</div>
  //           }
  //         }
  //       })}
  //       <ReCAPTCHA
  //         ref={(el) => { this.captcha = el; }}
  //         sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
  //         onChange={this.onCapthaChange}
  //       />
  //       <Button type="submit" bsStyle="primary" disabled={!this.state.formValid && !this.state.submitting}>
  //         Verzend
  //       </Button>
  //     </form>
  //   )
  // }
}
