import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export default class Clipboard extends Component {
  state = {
    copied: false
  };

  fadeOut = () => {
    setTimeout(
      function() {
        this.setState({
          copied: false
        });
      }.bind(this),
      5000
    );
  };

  render() {
    // {this.state.copied ? <span style={{color: 'red'}}>Copied.</span> : null}
    return (
      <CopyToClipboard
        text={this.props.text}
        onCopy={() => this.setState({ copied: true }, this.fadeOut)}
      >
        <div className="SocialMediaShareButton">
          <div className="Btn BtnShareSnip" alt="Kopieer link" title="Kopieer link" />
          <div className={'BtnShareSnipMessage' + (this.state.copied ? ' copied' : '')}>
            Gekopieëerd
          </div>
        </div>
      </CopyToClipboard>
    );
  }
}
