import React, { Component } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { decoratedFetch } from './../helpers/fetch';

import Heroes from '../components/Heroes';
import DoubleCarousel from '../components/DoubleCarousel';
import Spinner from '../components/Spinner';
import LadderRow from '../components/LadderRow';

import PageHeader from '../components/PageHeader';
import Footer from '../components/Footer';
import HeaderTags from '../helpers/HeaderTags';

import '../css/homepage.css';

class MediaPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: [],
      filtered_events: [],
      hasMoreItems: true,
      nextHref: null
    };
  }

  loadItems = page => {
    var url = '/events/albums.json?page=1';
    if (this.state.nextHref) {
      url = this.state.nextHref;
    }

    decoratedFetch(url)
      .then(response => response.json())
      .then(data => {
        if (data.next) {
          this.setState({
            events: this.state.events.concat(data.events),
            nextHref: data.next
          });
        } else {
          this.setState({
            events: this.state.events.concat(data.events),
            nextHref: null,
            hasMoreItems: false
          });
        }
      });
  };

  render() {
    let events = this.state.events.map((item, idx) => (
      <LadderRow item={item} key={item.id} idx={idx} />
    ));
    return (
      <React.Fragment>
        <Heroes highlites={this.state.highlites} />
        <DoubleCarousel carousel_events={this.state.carousel_events} />
        <PageHeader>MEDIA</PageHeader>
        <div className="container-fluid bg-color">
          <div className="container">
            <div className="row">
              <InfiniteScroll
                pageStart={1}
                loadMore={this.loadItems}
                hasMore={this.state.hasMoreItems}
                loader={<Spinner />}
              >
                <div id="ladder">{events}</div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
        <Footer />
        <HeaderTags title="HOME" />
      </React.Fragment>
    );
  }
}

export default MediaPage;
