import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap';

const TextArea = props => {
  // return <Input componentClass='textarea' type='textarea' {...props}/>
  return (
    <FormGroup
      controlId={props.controlId}
      // validationState={this.props.getValidationState}
    >
      {props.label && <ControlLabel>{props.label}</ControlLabel>}
      {props.required && <span className="required">*</span>}
      <FormControl
        name={props.controlId}
        componentClass="textarea"
        value={props.value}
        placeholder={props.placeholder}
        onChange={props.handleChange}
        autoComplete={props.controlId}
        required={props.required}
      />
      <FormControl.Feedback />
      {props.help && <HelpBlock>{props.help}</HelpBlock>}
    </FormGroup>
  );
};

TextArea.propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  // getValidationState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string
};

export default TextArea;
