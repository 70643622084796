import React, { Component } from 'react';
import { decoratedFetch } from '../helpers/fetch';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import '../css/content_blocks.css';

export default class ContentBlocks extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: props.pages || [],
      loading: false
    };
  }

  componentDidMount() {
    if (this.state.pages.length === 0) {
      this.setState({ loading: true });
      decoratedFetch(
        '/pages.json?shuffle=true&category=' + this.props.tag + '&limit=' + this.props.loadAmount
      )
        .then(response => response.json())
        .then(data => this.setState({ pages: data.pages, loading: false }));
    }
  }

  render() {
    var childElements = this.state.pages.map((page, idx) => (
      <Link to={'/' + page.slug} key={idx}>
        <div className="ContentBlock col-xs-6 col-sm-6 col-md-4" key={idx}>
          <div className="wrapper_1_1">
            <div
              className="ImageBlock"
              style={{ backgroundImage: 'url(' + page.main_image_sizes.image_big + ')' }}
            />
          </div>
          <div className="title">{page.title}</div>
        </div>
      </Link>
    ));
    if (this.state.loading) {
      return (
        <div className="ContentBlocks">
          <Spinner />
        </div>
      );
    } else {
      return <div className="ContentBlocks">{childElements}</div>;
    }
  }
}
