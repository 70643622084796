import React from 'react';
import '../css/master.css';
import { BrowserRouter, Route, NavLink, Redirect } from 'react-router-dom';
import { spring, AnimatedSwitch } from 'react-router-transition';

import HomePage from './HomePage';
import MediaPage from './MediaPage';
import DinnersPage from './DinnersPage';
import NewsPage from './NewsPage';
import NewsItemPage from './NewsItemPage';
import EventPage from './EventPage';
import ContentPage from './ContentPage';
import TestPage from './TestPage';
import MenuPage from './MenuPage';
import PreviewPage from './PreviewPage';

import { SearchFilterOptionsContext } from '../components/SearchFilterOptionsContext';
import CookieConsent, { Cookies, getCookieConsentValue } from 'react-cookie-consent';

import Menu from '../components/Menu';
import { SvgFilters } from '../components/SvgFilters';
import ScrollToTop from '../helpers/ScrollToTop';
import { initGTM } from '../helpers/GoogleTagManager.js';

import '../fonts/klavikaburger-bold-webfont.eot';
import '../fonts/klavikaburger-bold-webfont.woff';
import '../fonts/klavikaburger-bold-webfont.ttf';
import '../fonts/klavikaburger-regular-webfont.eot';
import '../fonts/klavikaburger-regular-webfont.woff';
import '../fonts/klavikaburger-regular-webfont.ttf';
import '../fonts/klavikaburger-light-webfont.eot';
import '../fonts/klavikaburger-light-webfont.woff';
import '../fonts/klavikaburger-light-webfont.ttf';
import '../css/klavika.css';
import '../css/app.css';

// we need to map the `scale` prop we define below
// to the transform style property
function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `scale(${styles.scale})`,
  };
}

// wrap the `spring` helper to use a bouncy config
function bounce(val) {
  return spring(val, {
    stiffness: 330,
    damping: 22,
  });
}

// child matches will...
const bounceTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    scale: 1.2,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: bounce(0),
    scale: bounce(0.8),
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: bounce(1),
    scale: bounce(1),
  },
};

const handleAcceptCookie = () => {
  initGTM(process.env.REACT_APP_GTM);
};

const handleDeclineCookie = () => {
  // remove google analytics cookies
  Object.keys(Cookies.get()).map(cookieKey => {
    if (/^_g.*/g.exec(cookieKey)) {
      Cookies.remove(cookieKey)
    }
  })
  // remove FB cookies
  Cookies.remove('sb');
  Cookies.remove('oo');
  Cookies.remove('dpr');
  Cookies.remove('datr');
  Cookies.remove('_fbp');
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filters: [[null, 'ALLES']],
    };
  }

  updateAvailableFilters = (filters) => this.setState({ filters });

  componentDidMount() {
    const isConsent = getCookieConsentValue();
    console.log('isConsent', isConsent);
    if (isConsent === 'true') {
      handleAcceptCookie();
    } else {
      handleDeclineCookie();
    }
  }

  render() {
    let props = this.props;
    const contextValue = {
      filters: this.state.filters,
      updateAvailableFilters: this.updateAvailableFilters,
    };
    return (
      <BrowserRouter>
        <ScrollToTop>
          <CookieConsent
            enableDeclineButton
            onAccept={handleAcceptCookie}
            onDecline={handleDeclineCookie}
            buttonText="Let's go!"
            declineButtonText="Nee dank je"
            style={{
              // background: "linear-gradient(to right, orange , yellow, green, cyan, blue, violet)",
              backgroundColor: "#33beca",
            }}
            declineButtonStyle={{
              backgroundColor: "#33BECA",
              border: "2px solid #f1f1f1",
              color: "#f1f1f1",
            }}
            buttonStyle={{
              backgroundColor: "black",
              color: "white",
              fontWeight: "bolder",
              textShadow: "1px 1px black",
            }}          >
            Burgerweeshuis maakt gebruik van cookies. <br/>
            Lees ons <NavLink to="/info/privacy">privacy statement</NavLink> voor meer informatie
          </CookieConsent>
          <SearchFilterOptionsContext.Provider value={contextValue}>
            <Menu />
            <AnimatedSwitch
              atEnter={bounceTransition.atEnter}
              atLeave={bounceTransition.atLeave}
              atActive={bounceTransition.atActive}
              mapStyles={mapStyles}
              className="route-wrapper"
            >
              <Route exact path="/test" component={TestPage} />
              <Route
                exact
                path="/"
                render={(routeProps) => <HomePage {...routeProps} {...props} />}
              />
              <Route
                exact
                path="/agenda"
                render={(routeProps) => <HomePage {...routeProps} {...props} />}
              />
              <Route
                exact
                path="/media"
                render={(routeProps) => <MediaPage {...routeProps} {...props} />}
              />
              <Route
                exact
                path="/burgercafe"
                render={(routeProps) => <DinnersPage {...routeProps} {...props} />}
              />
              <Route exact path="/nieuws" component={NewsPage} />
              <Route exact path="/menu" component={MenuPage} />
              <Route path={`/nieuws/:newsId`} component={NewsItemPage} />
              <Route path={`/agenda/:eventId`} component={EventPage} />
              <Redirect from="/weesjes-kantine/:id" to="/burgercafe/:id" />
              <Redirect from="/weesjes-kantine" to="/burgercafe" />
              <Route path={`/burgercafe/:eventId`} component={EventPage} />
              <Route path={`/static/preview`} component={PreviewPage} />
              <Route path="/*" component={ContentPage} />
            </AnimatedSwitch>
          </SearchFilterOptionsContext.Provider>
          <SvgFilters />
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
