import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

function BreadCrumbs(props) {
  let path = '';
  let crumbs = props.breadcrumbs.map((item, idx, arr) => {
    path += '/' + item;
    if (arr.length === idx + 1) {
      return (
        <span key={idx} className="crumb">
          {idx === 0 ? '' : ' / '}
          {item}
        </span>
      );
    } else {
      return (
        <span key={idx} className="crumb">
          {idx === 0 ? '' : ' / '}
          <Link to={path}>{item}</Link>
        </span>
      );
    }
  });
  return (
    <div className="row" id="breadcrumbs">
      {crumbs}
    </div>
  );
}

BreadCrumbs.propTypes = {
  breadcrumbs: PropTypes.array.isRequired
};
export default BreadCrumbs;
