import React, { Component } from 'react';

export default class BtnDinnerReservation extends Component {
  onClickHandler = e => {
    e.preventDefault();
    if (this.props.event && this.props.event.dinner_reservation_url) {
      window.open(this.props.event.dinner_reservation_url, '_blank');
    }
  };

  render() {
    let event = this.props.event;
    let ctaText = 'RESERVEREN';
    if (event.sold_out) {
      ctaText = 'VOL';
    }
    return (
      <a className={this.props.className} onClick={this.onClickHandler}>
        {ctaText}
      </a>
    );
  }
}
