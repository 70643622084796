import React from 'react';
import '../../css/btn.css';

export default function LoadMore(props) {
  return (
    <div
      className={[
        'BtnLoadMore ',
        props.expanded ? 'expanded' : '',
        props.reversed ? 'reversed' : ''
      ].join(' ')}
    >
      <div className="loadMore">{props.expanded ? 'SLUIT' : 'MEER LADEN'}</div>
      <div className="loadMoreArrow" onClick={props.handleClick} />
    </div>
  );
}
