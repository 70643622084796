import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Btn from './btn/Btn';
import '../css/header.css';

class Header extends Component {
  handleMailingClick = () => {
    window.scrollTo(0, document.body.scrollHeight);
  };

  render() {
    return (
      <header>
        <div className="container">
          <button
            type="button"
            onClick={this.props.handleClick}
            className={
              'hamburger hamburger--slider ' + (this.props.expanded ? 'expanded is-active' : '')
            }
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
          <div id="menu" className="hidden-xs" onClick={this.props.handleClick}>
            <a target="_blank" rel="noreferrer noopener" href="https://twitter.com/burgerweeshuis">
              <Btn className="BtnSm BtnSmTwitter" />
            </a>
            <a target="_blank" rel="noreferrer noopener" href="https://facebook.com/burgerweeshuis">
              <Btn className="BtnSm BtnSmFacebook" />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://instagram.com/burgerweeshuis"
            >
              <Btn className="BtnSm BtnSmInstagram" />
            </a>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href="https://open.spotify.com/playlist/6Mm40GouWyc6xEmxxJ0uKD?si=9286be519754457d"
            >
              <Btn className="BtnSm BtnSmSpotify" />
            </a>
          </div>
          <h1>
            <Link to="/" id="logo">
              BURGERWEESHUIS
            </Link>
          </h1>
          {/* <div className="col-xs-2" id="signup-email" onClick={this.handleMailingClick}>
            NIEUWSBRIEF
          </div> */}
        </div>
      </header>
    );
  }
}

export default Header;
