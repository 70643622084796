import React from 'react';
import PropTypes from 'prop-types';
import { FormGroup, ControlLabel, Checkbox, HelpBlock } from 'react-bootstrap';

const MyCheckbox = props => {
  return (
    <FormGroup
      controlId={props.controlId}
      // validationState={this.props.getValidationState}
    >
      {props.label && <ControlLabel>{props.label}</ControlLabel>}
      <Checkbox {...props} name={props.controlId} onChange={props.handleChange}>
        {props.children}
      </Checkbox>
      {props.help && <HelpBlock>{props.help}</HelpBlock>}
    </FormGroup>
  );
};

MyCheckbox.propTypes = {
  controlId: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  // getValidationState: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  help: PropTypes.string
};

export default MyCheckbox;
