import React, { Component } from 'react';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';

import Heroes from '../components/Heroes';
import DoubleCarousel from '../components/DoubleCarousel';
import PageHeader from '../components/PageHeader';
import EventsPageHeader from '../components/EventsPageHeader';
import Ladder from '../components/Ladder';
import News from '../components/News';
import MailingList from '../components/MailingList';
import Footer from '../components/Footer';
import HeaderTags from '../helpers/HeaderTags';

import '../css/homepage.css';

class HomePage extends Component {
  static propTypes = {
    cookies: instanceOf(Cookies).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      agenda_events: props.inline_data.events ? JSON.parse(props.inline_data.events) : [],
      carousel_events: props.inline_data.carouselEvents
        ? JSON.parse(props.inline_data.carouselEvents)
        : [],
      highlites: props.inline_data.highlites ? JSON.parse(props.inline_data.highlites) : [],
      news_items: props.inline_data.newsItems ? JSON.parse(props.inline_data.newsItems) : [],
      albums: props.match.path === '/media',
      filter: null,
      search_value: null
    };
  }

  componentDidMount = () => {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  };

  componentWillUnmount = () => {
    window.removeEventListener('resize', this.updateWindowDimensions);
  };

  UNSAFE_componentWillMount = () => {
    const { cookies } = this.props;
    let displayLadder = cookies.get('displayLadder_v2');
    if (displayLadder === undefined) {
      this.setState({ displayLadder: true });
    } else {
      this.setState({ displayLadder: displayLadder === 'true' });
    }
  };
  UNSAFE_componentWillReceiveProps = nextProps => {
    if (this.state.albums !== (nextProps.match.path === '/media')) {
      this.setState({
        albums: nextProps.match.path === '/media',
        agenda_events: [],
        carousel_events: [],
        filter: null,
        search_value: null
      });
    }
  };

  filterLadder = (filter, filter_name) => {
    this.setState({ filter, filter_name });
  };

  handleBlocksToggle = () => {
    const { cookies } = this.props;
    cookies.set('displayLadder_v2', !this.state.displayLadder, { path: '/' });
    this.setState({ displayLadder: !this.state.displayLadder });
  };
  searchLadder = search_value => {
    this.setState({ search_value: search_value });
  };
  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    return (
      <React.Fragment>
        <Heroes highlites={this.state.highlites} />
        <DoubleCarousel carousel_events={this.state.carousel_events} />
        <div className="container-fluid bg-color">
          <EventsPageHeader
            title={this.state.filter ? this.state.filter_name.toUpperCase() : 'AGENDA'}
            handleBlocksToggle={this.handleBlocksToggle}
            displayLadder={this.state.displayLadder}
            filterLadder={this.filterLadder}
            searchLadder={this.searchLadder}
          />
          <div className="container">
            <div className="row">
              <Ladder
                displayLadder={this.state.displayLadder}
                forceLadder={this.state.width < 768}
                agendaEvents={this.state.agenda_events}
                filter={this.state.filter}
                searchValue={this.state.search_value}
                albums={this.state.albums}
              />
            </div>
          </div>
        </div>
        <MailingList />
        <div className="container-fluid inverted-bg-color">
          <PageHeader>NIEUWS</PageHeader>
          <div className="container">
            <News news_items={this.state.news_items} />
          </div>
        </div>
        <Footer />
        <HeaderTags title="HOME" />
      </React.Fragment>
    );
  }
}

export default withCookies(HomePage);
