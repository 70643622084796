import React from 'react';
import '../css/page_header.css';

export default function PageHeader(props) {
  return (
    <div className="container-fluid PageHeaderWrapper">
      <div className="container PageHeader">
        {props.children}
        <div className="row dash_wrapper">
          <div className="dash" />
        </div>
      </div>
    </div>
  );
}
