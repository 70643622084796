import React, { Component } from 'react';

import Heroes from '../components/Heroes';
import DoubleCarousel from '../components/DoubleCarousel';
import PageHeader from '../components/PageHeader';
import DinnerLadder from '../components/DinnerLadder';
import HeaderTags from '../helpers/HeaderTags';
import '../css/homepage.css';

export default class DinnersPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dinner_events: props.inline_data.dinners ? JSON.parse(props.inline_data.dinners) : [],
      carousel_events: props.inline_data.carouselEvents
        ? JSON.parse(props.inline_data.carouselEvents)
        : [],
      highlites: props.inline_data.highlites ? JSON.parse(props.inline_data.highlites) : [],
      filter: null,
      search: null
    };
  }

  render() {
    return (
      <div>
        <Heroes highlites={this.state.highlites} />
        <DoubleCarousel carousel_events={this.state.carousel_events} />
        <div className="container-fluid bg-color">
          <PageHeader title="BURGERCAFÉ">
            <div className="col-xs-12">BURGERCAFÉ</div>
          </PageHeader>
          <div className="container">
            <DinnerLadder agendaEvents={this.state.dinner_events} />
          </div>
        </div>
        <HeaderTags title="BURGERCAFÉ" />
      </div>
    );
  }
}
