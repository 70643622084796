import React from 'react';
import { Link } from 'react-router-dom';
import BtnMore from './btn/more';
import BtnComment from './btn/Comment';
import { titledId } from './../helpers/titledId';
import '../css/news_item_teaser.css';

export default function NewsItemTeaser(props) {
  let item = props.item;
  if (props.selected) {
    return <div />;
  } else {
    return (
      <div className={'col-xs-12 col-sm-6 NewsItemTeaser ' + (props.selected ? 'selected' : '')}>
        <div className="col-xs-12">
          <Link to={'/nieuws/' + titledId(item)}>
            <BtnComment />
            <img src={item.main_image_sizes.image_big} alt="" />
            <div className="title">{item.title}</div>
          </Link>
          <div className="teaser" dangerouslySetInnerHTML={{ __html: item.teaser }} />
        </div>
        <div className="col-xs-12">
          <BtnMore to={'/nieuws/' + titledId(item)} />
        </div>
      </div>
    );
  }
}
