import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import Header from './Header';
import '../css/menu.css';
import btn_right from '../images/Ico/ArrowRight.svg';

export default class Menu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: false
    };
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
    document.addEventListener('mousedown', this.handleClickOutside, false);
    // this.wrapperRef.current.addEventListener('mouseleave', this.handleMouseLeave, false);
  }
  componentWillUnmount() {
    // this.wrapperRef.current.removeEventListener('mouseleave', this.handleMouseLeave, false);
    document.removeEventListener('mousedown', this.handleClickOutside, false);
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction = event => {
    if (event.keyCode === 27 && this.state.expanded) {
      this.handleClick();
    }
  };

  handleClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };
  handleClickOutside = event => {
    if (!this.wrapperRef.current.contains(event.target) && this.state.expanded) {
      this.handleClick();
    }
  };

  handleMouseLeave = event => {
    if (this.state.expanded) {
      this.handleClick();
    }
  };

  render() {
    return (
      <div ref={this.wrapperRef}>
        <Header handleClick={this.handleClick} expanded={this.state.expanded} />
        <div
          className={'container-fluid Menu ' + (this.state.expanded ? 'expanded' : '')}
          onClick={this.handleClick}
        >
          <div className="container">
            <div className="hidden-xs col-sm-2" />
            <div className="col-xs-12 col-sm-2 bordered">
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/">
                AGENDA
              </NavLink>
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/nieuws">
                NIEUWS
              </NavLink>
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/info/contact">
                CONTACT
              </NavLink>
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/info/tickets">
                TICKETS
              </NavLink>
              <NavLink
                exact
                activeClassName="active"
                onClick={this.handleClick}
                to="/menu"
                className="visible-xs"
              >
                <img src={btn_right} alt="Uitgebreid menu" />
              </NavLink>
            </div>
            <div className="hidden-xs col-sm-2 bordered">
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/info">
                INFO
              </NavLink>
              <ol>
                <li>
                  <NavLink exact activeClassName="active" onClick={this.handleClick} to="/nieuwsbrief">
                    NIEUWSBRIEF
                  </NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" onClick={this.handleClick} to="/info/faq">
                    FAQ
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/info/techspecs"
                  >
                    TECH SPECS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/nachtburger"
                  >
                    NACHTBURGER
                  </NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" onClick={this.handleClick} to="/info/vacatures">
                    VACATURES
                  </NavLink>
                </li>
              </ol>
            </div>
            <div className="hidden-xs col-sm-2 bordered">
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/talent">
                TALENT
              </NavLink>
              <ol>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/burgerbeurs"
                  >
                    BURGERBEURS
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/speeldrang"
                  >
                    SPEELDRANG
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/ndc"
                  >
                    NDC
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/talenthouse"
                  >
                    TALENTHOUSE
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/samenwerkingen"
                  >
                    SAMENWERKINGEN
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/oefenruimtes"
                  >
                    OEFENRUIMTES
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/talent/educatie"
                  >
                    EDUCATIE
                  </NavLink>
                </li>
              </ol>
            </div>
            <div className="hidden-xs col-sm-2">
              <NavLink exact activeClassName="active" onClick={this.handleClick} to="/zakelijk">
                ZAKELIJK
              </NavLink>
              <ol>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/zakelijk/verhuur"
                  >
                    VERHUUR
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/zakelijk/burgerbusinessclub"
                  >
                    BUSINESS CLUB
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    exact
                    activeClassName="active"
                    onClick={this.handleClick}
                    to="/zakelijk/events"
                  >
                    EVENTS
                  </NavLink>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
