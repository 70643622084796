import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import { titledId } from './../helpers/titledId';

export default function EventLink(props) {
  const { event, ...rest } = props;
  if (event.alternative_url) {
    return (
      <Link to={event.alternative_url} {...rest}>
        {props.children}
      </Link>
    );
  } else if ('Event' === event.type) {
    return (
      <Link to={'/agenda/' + titledId(event)} {...rest}>
        {props.children}
      </Link>
    );
  } else if ('News' === event.type) {
    return (
      <Link to={'/nieuws/' + titledId(event)} {...rest}>
        {props.children}
      </Link>
    );
  } else if ('Dinner' === event.type) {
    return (
      <Link to={'/burgercafe/' + titledId(event)} {...rest}>
        {props.children}
      </Link>
    );
  } else {
    return <div>{props.children}</div>;
  }
}
EventLink.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired
};
