import React, { Component } from 'react';
import NewsItemTeaser from './NewsItemTeaser';
import { decoratedFetch } from './../helpers/fetch';
import LoadMore from './btn/LoadMore';
import Spinner from './Spinner';
import '../css/news.css';

export default class News extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news_items: props.news_items || [],
      expanded: false,
      loadAmount: this.props.loadAmount || 2,
      loading: true
    };
  }

  componentDidMount() {
    if (this.state.news_items.length === 0) {
      this.setState({ loading: true });
      decoratedFetch('/news.json?category=' + (this.props.tag || ''))
        .then(response => response.json())
        .then(({ news: items }) => this.setState({ news_items: items, loading: false }));
    }
  }

  handleClick = () => {
    this.setState({ expanded: !this.state.expanded });
  };

  render() {
    let news_items = this.state.news_items;
    if (this.state.loading) {
      return (
        <div id="ladder">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div className="News">
          <div className="row">
            <div className="col-md-1" />
            <div className="col-md-10">
              <div className="row">
                {news_items
                  .slice(
                    0,
                    this.state.expanded ? this.state.news_items.length : this.state.loadAmount
                  )
                  .map((item, idx) => (
                    <NewsItemTeaser
                      item={item}
                      key={item.id}
                      idx={idx}
                      selected={item.id === parseInt(this.props.newsItemId, 10)}
                    />
                  ))}
              </div>
            </div>
            <div className="col-md-1" />
          </div>
          <div className="row">
            {!this.props.tag && (
              <LoadMore
                handleClick={this.handleClick}
                expanded={this.state.expanded}
                reversed={true}
              />
            )}
          </div>
        </div>
      );
    }
  }
}
