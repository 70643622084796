import React from 'react';
import { Link } from 'react-router-dom';
import BtnTicket from './btn/ticket';
import '../css/related.css';

const Related = props => (
  <div className="container">
    <div className="row Related no-gutter">
      {props.related &&
        props.related.map((highlite, idx) => (
          <div className="col-xs-6 col-sm-3 RelatedBlock" key={idx}>
            <Link to={highlite.event_path}>
              <div className="wrapper_1_1">
                <div
                  className="ImageBlock"
                  style={{ backgroundImage: 'url(' + highlite.main_image_sizes.image_big + ')' }}
                >
                  <BtnTicket event={highlite} right={true} />
                  <div className="title">{highlite.title}</div>
                </div>
              </div>
            </Link>
          </div>
        ))}
    </div>
  </div>
);

export default Related;
