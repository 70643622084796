import React, { Component } from 'react';
import { convertNodeToElement } from 'react-html-parser';
import Ladder from '../components/Ladder';
import News from '../components/News';
import ContentBlocks from '../components/ContentBlocks';
import ContentBlocksBBC from '../components/ContentBlocksBBC';
import MediaCarouselFetch from '../components/MediaCarouselFetch';

import Form from '../components/forms/Form';

export function transform(node, index) {
  if (node.type === 'tag' && node.name === 'form' && node.attribs.name !== 'mc-embedded-subscribe-form') {
    return <Form key={index} index={index} name={node.attribs.name} />;
  }
  if (node.type === 'tag' && node.name === 'topic') {
    return (
      <Topic key={index} index={index}>
        {node.children}
      </Topic>
    );
  }
  if (node.type === 'tag' && node.name === 'tag') {
    if (node.attribs.ref) {
      var ref = node.attribs.ref.split('=');
      if (ref[0] === 'events') {
        return (
          <div className="row" key={index}>
            <Ladder tag={ref[1]} forceLadder={true}/>
          </div>
        );
      }
      if (ref[0] === 'news') {
        return <News tag={ref[1]} loadAmount="8" key={index}/>;
      }
      if (ref[0] === 'page') {
        return <ContentBlocks tag={ref[1]} loadAmount="99" key={index}/>;
      }
      if (ref[0] === 'bbc') {
        return <ContentBlocksBBC tag={ref[1]} key={index}/>;
      }
      if (ref[0] === 'media') {
        return <MediaCarouselFetch tag={ref[1]} key={index}/>;
      }
    }
  }
}

export class Topic extends Component {
  constructor(props) {
    super(props);
    this.state = {
      folded: true
    };
  }
  handleClick = () => {
    this.setState({ folded: !this.state.folded });
  };
  render() {
    return this.props.children.map((node, index) => {
      if (node.type === 'tag' && node.name === 'question') {
        return (
          <dt
            key={index}
            onClick={this.handleClick}
            className={'question ' + (this.state.folded ? 'folded' : '')}
          >
            {node.children.map((node, index) => convertNodeToElement(node, index, transform))}
          </dt>
        );
      }
      if (node.type === 'tag' && node.name === 'answer' && !this.state.folded) {
        return (
          <dd key={index} onClick={this.handleClick} className="answer">
            {node.children.map((node, index) => convertNodeToElement(node, index, transform))}
          </dd>
        );
      }
      return undefined;
    });
  }
}
