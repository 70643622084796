import React, { Component } from 'react';
import { Carousel } from 'react-bootstrap';
import { decoratedFetch } from '../helpers/fetch';

import '../css/carousel.css';

export default class MediaCarouselFetch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tag: this.props.tag,
      medias: null
    };
  }

  componentDidMount() {
    this.getItems(this.state.tag);
  }
  getItems = tag => {
    decoratedFetch('/media.json?category=' + tag )
      .then(response => response.json())
      .then(data => this.setState(data))
      .catch(e => {
        this.setState(
          {},
          console.log('error: ', e.message)
        );
      });
  };

  render() {
    let medias = this.state.medias;
    if (medias && medias.length > 0) {
      return (
        <div className="embed-responsive embed-responsive-16by9 embed-responsive-2by1">
          <Carousel
            className="embed-responsive-item"
            nextIcon={<div className="Btn BtnCarousel BtnRight" />}
            prevIcon={<div className="Btn BtnCarousel BtnLeft" />}
          >
            {medias.map((item, idx) => (
              <Carousel.Item key={idx}>
                {item.type === 'image' && (
                  <img src={item.image_inline} alt={item.title} />
                )}
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
      );
    } else {
      return <span />
    }
  }
}
