import React, { Component } from 'react';
import { decoratedFetch } from './../helpers/fetch';
import Spinner from './../components/Spinner';
import { NavLink } from 'react-router-dom';

import '../css/menupage.css';

export default class MenuPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      menu_items: []
    };
  }
  componentDidMount() {
    this.setState({ loading: true }, this.retrieveMenu);
  }

  retrieveMenu = () => {
    decoratedFetch('/menu.json')
      .then(response => response.json())
      .then(data => this.setState({ menu_items: data.pages, loading: false }))
      .catch(e => {
        console.log('error: ', e.message);
      });
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="container MenuPage">
          <article className="col-xs-12 col-sm-8">
            {this.state.loading ? <Spinner /> : <MenuItem>{this.state.menu_items}</MenuItem>}
          </article>
        </div>
      </div>
    );
  }
}

const MenuItem = props => {
  return (
    <ul>
      {props.children.map((item, idx) => (
        <ListElement item={item} key={idx} />
      ))}
    </ul>
  );
};
const ListElement = props => {
  if (props.item.show_in_menu) {
    return (
      <li>
        <NavLink to={'/' + props.item.slug}>{props.item.title}</NavLink>
        <MenuItem>{props.item.children}</MenuItem>
      </li>
    );
  } else {
    return <MenuItem>{props.item.children}</MenuItem>;
  }
};
