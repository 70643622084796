import React, { Component } from 'react';
import { SearchFilterOptionsContext } from '../SearchFilterOptionsContext';
import '../../css/btn_search.css';

export default class BtnSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active_li: null,
      filter: null,
      open: props.open,
      search_value: ''
    };
    this.textInput = React.createRef();
    this.wrapperRef = React.createRef();
  }

  componentDidMount() {
    this.wrapperRef.current.addEventListener('mouseenter', this.handleMouseEnter, false);
  }
  componentWillUnmount() {
    this.wrapperRef.current.removeEventListener('mouseenter', this.handleMouseEnter, false);
  }

  filterLadder = () => {
    this.props.filterLadder(this.state.filter, this.state.filter_name);
  };

  focusTextInput = () => {
    this.textInput.current.focus();
  };

  handleChange = e => {
    this.setState(
      { search_value: e.target.value, open: e.target.value.length > 0 },
      this.searchLadder
    );
  };

  handleClick = (id, filter_name, filter, e) => {
    this.setState({ active: id, filter_name: filter_name, filter: filter }, this.filterLadder);
  };

  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.searchLadder();
    }
  };
  handleMouseEnter = () => {
    this.focusTextInput();
  };

  searchLadder = () => {
    this.props.searchLadder(this.state.search_value);
  };

  render() {
    let bordered = this.props.bordered ? 'BtnBordered' : '';
    return (
      <div className={'BtnSearchWrapper ' + (this.state.open ? 'open' : '')} ref={this.wrapperRef}>
        <ul className="SearchList">
          <input
            className="SearchForm"
            type="text"
            placeholder="Zoek"
            value={this.state.search_value}
            onChange={this.handleChange}
            onKeyPress={this.handleKeyPress}
            ref={this.textInput}
          />
          <SearchFilterOptionsContext.Consumer>
            {({ filters }) => {
              return filters.map((item, idx) => (
                <li
                  key={idx}
                  className={this.state.filter === item[0] ? 'active' : ''}
                  onClick={this.handleClick.bind(this, idx, item[1], item[0])}
                >
                  {item[1].toUpperCase()}
                </li>
              ));
            }}
          </SearchFilterOptionsContext.Consumer>
        </ul>
        <button className={'Btn BtnSearch ' + bordered} onClick={this.searchLadder} />
      </div>
    );
  }
}
