import React, { Component } from 'react';
import BtnDinnerReservation from './BtnDinnerReservation';
import '../../css/btn.css';
import { eventStateListValues } from './../../helpers/eventStateListValues';

class BtnTicket extends Component {
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  onClickHandler = e => {
    e.preventDefault();
    if (this.props.event && this.props.event.ticket_url) {
      window.open(this.props.event.ticket_url, '_blank');
    }
  };

  btnRef = React.createRef();

  handleScroll = event => {
    if (this.btnRef.current) {
      let lastScrollY = window.scrollY;
      if (lastScrollY > 80) {
        this.btnRef.current.style.top = `${lastScrollY - 75}px`;
        this.btnRef.current.style.position = `absolute`;
      }
    }
  };

  render() {
    let event = this.props.event || false;
    let cornered = this.props.cornered || false;
    let right = this.props.right || false;
    let no_fly = this.props.noFly || false;
    let inline = this.props.inline || false;
    let black_bg = this.props.blackBg || false;
    let rotate_90 = this.props.rotate90 || false;
    let sticky = this.props.sticky || false;
    if (inline) {
      no_fly = true;
    }
    let showTicketButton = true;
    let btnClassName = 'BtnTicket';
    let utilityClassName = 'BtnTicketFlyout__default';
    let ctaClass = 'buy';
    let ctaText = 'KOOP TICKETS';
    const currentTime = new Date();
    const eventTime = new Date(event.closes_at_iso);

    if (eventTime < currentTime) {
      return <span />;
    } else if (event && event.type === 'Dinner') {
      return <BtnDinnerReservation event={event} className="BtnDinnerReservation right" />;
    } else if (event) {
      if (event.event_state_list && event.event_state_list.length > 0) {
        ctaText = eventStateListValues[event.event_state_list[0]];
        ctaClass = event.event_state_list[0];
        utilityClassName = 'BtnTicketFlyout__' + ctaClass;
        if (event.event_state_list[0] === 'cancelled') {
          btnClassName = 'BtnCancelled';
          showTicketButton = false;
          event.ticket_url = null;
        }
        if (event.sold_out) {
          btnClassName = 'BtnSoldOut';
          utilityClassName = 'BtnTicketFlyout__sold_out';
        } else if (event.free_event) {
          btnClassName = 'BtnFree';
          utilityClassName = 'BtnTicketFlyout__free_event';
        }
      } else if (event.free_event) {
        btnClassName = 'BtnFree';
        ctaText = 'GRATIS';
        ctaClass = 'free';
        utilityClassName = 'BtnTicketFlyout__free_event';
        no_fly = true;
      } else if (event.sold_out) {
        btnClassName = 'BtnSoldOut';
        ctaText = 'UITVERKOCHT';
        ctaClass = 'soldout';
        utilityClassName = 'BtnTicketFlyout__sold_out';
      } else if (typeof event.ticket_url === 'undefined') {
        return <span />;
      }
      return (
        <div
          className={[
            'BtnTicketFlyout',
            utilityClassName,
            cornered ? 'cornered' : '',
            right ? 'right' : 'left'
          ].join(' ')}
          ref={sticky && this.btnRef}
        >
          {rotate_90 && (
            <div className="rotate-90" onClick={this.onClickHandler}>
              {ctaText}
            </div>
          )}
          {showTicketButton && !(event.free_event && inline) && (
            <div
              className={['Btn', btnClassName, black_bg ? 'black_bg' : ''].join(' ')}
              onClick={this.onClickHandler}
            >
              TICKET
            </div>
          )}
          {!no_fly && (
            <div className={'Flyout ' + ctaClass} onClick={this.onClickHandler}>
              {ctaText}
            </div>
          )}
          {inline && (
            <div className={'Inline ' + ctaClass} onClick={this.onClickHandler}>
              {ctaText}
            </div>
          )}
        </div>
      );
    } else {
      return <span />;
    }
  }
}

export default BtnTicket;
