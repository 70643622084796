import React, { Component } from 'react';
import Masonry from 'react-masonry-component';
import BtnTicket from './btn/ticket';
import EventLink from '../helpers/EventLink';
import DateBlock from './DateBlock';
import '../css/ladder_block.css';
import '../css/burgerhaven.css';

var masonryOptions = {
  transitionDuration: 0
};

const LadderBlocks = props => {
  var childElements = props.events.map((event, idx) => (
    <EventLink event={event} className={"LadderBlockWrapper col-xs-12 col-sm-6 col-md-3 " + event.venue_color}key={idx}>
      <div className="LadderBlock">
        <BtnTicket event={event} />
        <div className="wrapper_1_1">
          <div
            className="ImageBlock"
            style={{ backgroundImage: 'url(' + event.main_image_sizes.image_square + ')' }}
          />
          <DateBlock event={event} />
        </div>
        <div className="title">
          {event.title} <br />
          <span className="title--sub">{event.sub_title}</span>
        </div>
        <div className="details">{event.long_date}</div>
      </div>
    </EventLink>
  ));
  return (
    <Masonry
      // className={'my-gallery-class'} // default ''
      // elementType={'ul'} // default 'div'
      options={masonryOptions} // default {}
      disableImagesLoaded={false} // default false
      updateOnEachImageLoad={true} // default false and works only if disableImagesLoaded is false
    >
      {childElements}
    </Masonry>
  );
};
export default LadderBlocks;
