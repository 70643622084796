import 'react-app-polyfill/ie9';
import 'babel-polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import App from './spa/App';

// if (process.env.NODE_ENV !== 'production') {
//   const { whyDidYouUpdate } = require('why-did-you-update');
//   whyDidYouUpdate(React);
// }

const root = document.getElementById('root');
ReactDOM.render(
  <CookiesProvider>
    <App inline_data={root.dataset} />
  </CookiesProvider>,
  root
);
