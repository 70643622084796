import React, { Component } from 'react';
import '../css/media_item.css';

export default class MediaItem extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let item = this.props.item;
    let size = 'image_' + (this.props.size || 'big');
    if (item.type === 'link') {
      return (
        <div className="MediaItem Link">
          Link <a href={item.url}>{item.title}</a>
        </div>
      );
    } else if (item.type === 'file') {
      return (
        <div className="MediaItem File">
          <a href={item.url}>{item.title}</a>
        </div>
      );
    } else if (item.type === 'image') {
      return (
        <img className="MediaItem Image" src={item[size]} alt={item.title} title={item.title} />
      );
    } else {
      return <div className="MediaItem Unknown">Unknown {item.type}</div>;
    }
  }
}
