import React from 'react';
import '../../css/btn.css';

function BtnJustIn(props) {
  return (
    <span className="BtnJustIn--wrapper">
      <span className="Btn BtnJustIn">Just in</span>
      <span className="BtnJustIn--text">
        NET <br />
        BEVESTIGD
      </span>
    </span>
  );
}

export default BtnJustIn;
