import React, { Component } from 'react';
import GridHelper from '../helpers/GridHelper';
import { decoratedFetch } from './../helpers/fetch';
import Related from '../components/Related';

export default class TestPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      related: []
    };
  }
  componentDidMount() {
    if (this.state.related.length === 0) {
      decoratedFetch('/events/4879.json')
        .then(response => response.json())
        .then(data => this.setState(data));
    }
  }

  render() {
    return (
      <div>
        <GridHelper />
        <br />
        <br />
        <br />
        <br />
        <br />
        <Related related={this.state.related} />
      </div>
    );
  }
}
