import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { decoratedFetch } from './../helpers/fetch';

import '../css/footer.css';

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: props.pages ? JSON.parse(props.pages) : []
    };
  }

  componentDidMount() {
    if (this.state.pages.length === 0) {
      decoratedFetch('/pages.json?category=footer')
        .then(response => response.json())
        .then(({ pages: items }) => this.setState({ pages: items }));
    }
  }

  render() {
    return (
      <div className="container-fluid Footer">
        <div className="container">
          <div className="hidden-xs col-sm-1" />
          <div className="col-xs-12 col-sm-10 container FooterBlockWrapper">
            {this.state.pages &&
              this.state.pages.map((page, idx) => (
                <div className="col-xs-12 col-sm-4" key={idx}>
                  <FooterBlock page={page} />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

class FooterBlock extends Component {
  popUpCam = () => {
    window.open(
      '/kroegcam.html',
      'cam',
      'toolbar=0,scrollbars=0,location=0,statusbar=0,menubar=0,resizable=0,width=350,height=370'
    );
  };

  render() {
    const page = this.props.page;
    var bg_image = page.main_image_sizes.image_big;
    if (page.slug.match(/kroegcam/i)) {
      // bg_image = "http://glas.burgerweeshuis.nl:81/cam_front.jpg"
      return (
        <div className="FooterBlock kroegcam" onClick={this.popUpCam}>
          <div className="wrapper_1_1">
            <div className="ImageBlock" style={{ backgroundImage: 'url(' + bg_image + ')' }} />
          </div>
          <div className="title">{page.title}</div>
        </div>
      );
    } else {
      return (
        <Link to={page.slug} id="logo">
          <div className="FooterBlock">
            <div className="wrapper_1_1">
              <div className="ImageBlock" style={{ backgroundImage: 'url(' + bg_image + ')' }} />
            </div>
            <div className="title">{page.title}</div>
          </div>
        </Link>
      );
    }
  }
}
