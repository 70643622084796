import React from 'react';
import '../css/spinner.css';

export default function Spinner(props) {
  return (
    <div className={'spinner ' + props.className}>
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  );
}
