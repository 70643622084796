import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { Link } from 'react-router-dom';
import Hero from '../components/Hero';
import TitleBlock from '../components/TitleBlock';
import MediaItem from '../components/MediaItem';
import SocialShare from '../components/SocialShare';
import HeaderTags from '../helpers/HeaderTags';
import { decoratedFetch } from '../helpers/fetch';
import BreadCrumbs from '../components/BreadCrumbs';
import wees from '../images/404_wees.png';

import '../css/content_page.css';

import { transform } from '../helpers/transform';

export default class ContentPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug: this.props.match.url,
      page: null
    };
  }

  componentDidMount() {
    this.getPage(this.state.slug);
  }
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };

  getPage = slug => {
    decoratedFetch('/pages' + slug + '.json')
      .then(response => response.json())
      .then(data => this.setState(data))
      .catch(e => {
        this.setState(
          {
            page: this.fakePage({
              title: e.message,
              main_image: wees,
              main_image_sizes: {
                image_landing_big: wees
              },
              body: '<p>Deze pagina bestaat niet! </p><a href="/" class="btn btn-default">Ga terug naar de homepage</a>'
            })
          },
          console.log('error: ', e.message)
        );
      });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.match.url !== this.state.slug) {
      this.setState(
        {
          slug: nextProps.match.url,
          page: null
        },
        this.getPage(nextProps.match.url)
      );
    }
  };

  fakePage = (options = {}) => {
    return Object.assign(
      {
        title: '...',
        menu_items: [],
        main_image_sizes: {
          image_landing_big: null
        },
        breadcrumb: [],
        type: 'fake'
      },
      options
    );
  };

  render() {
    let page = this.state.page || this.fakePage({ title: 'Loading...' });
    let media = [];
    if (page.media) {
      media = page.media.filter(item => item.id !== page.main_image_sizes.id);
    }
    return (
      <div id="ContentPage">
        <Hero event={page} />
        <div className="container-fluid ContentPageContent">
          <div className="container">
            <article className="col-xs-12 col-sm-8">
              <TitleBlock title={page.title} />
              <BreadCrumbs breadcrumbs={page.breadcrumb} />
              <div className="row">
                {ReactHtmlParser(page.body, { decodeEntities: true, transform })}
              </div>
              {(page.side_bar_placement || page.side_bar) && (
                <div className="row">
                  {ReactHtmlParser(page.side_bar, { decodeEntities: true, transform })}
                </div>
              )}
              {media.length > 0 && (
                <div className="PageLinks">
                  <dl>
                    <dt>LINKS:</dt>
                    <dd>
                      {media.map((item, idx) => (
                        <MediaItem item={item} key={idx} size="medium" />
                      ))}
                    </dd>
                  </dl>
                </div>
              )}
            </article>
            <aside className="col-xs-12 col-sm-3">
              {page.menu_items.length > 0 && (
                <div className="col-xs-12 inverted-bg-color">
                  <ol id="aside_menu">
                    {page.menu_items.map((item, idx) => (
                      <li key={idx} className={'/' + item.slug === this.state.slug ? 'active' : ''}>
                        <Link to={'/' + item.slug}>{item.title}</Link>
                        {item.children.length > 0 && (
                          <ol>
                            {item.children.map((item, idx) => (
                              <li
                                className={'/' + item.slug === this.state.slug ? 'active' : ''}
                                key={idx}
                              >
                                <Link
                                  to={'/' + item.slug}
                                  className={'/' + item.slug === this.state.slug ? 'active' : ''}
                                >
                                  {item.title}
                                </Link>
                              </li>
                            ))}
                          </ol>
                        )}
                      </li>
                    ))}
                  </ol>
                </div>
              )}
              <SocialShare snip_url={page.snip_url} snip_title={page.snip_title} />
            </aside>
          </div>
        </div>
        {page && <HeaderTags title={page.title} />}
      </div>
    );
  }
}
