import React from 'react';
import BtnExpander from './btn/expander';
import BtnSearch from './btn/search';
import BtnSearchMobile from './btn/search_mobile';
import PageHeader from './PageHeader';
import '../css/events_page_header.css';

function EventsPageHeader(props) {
  return (
    <PageHeader>
      <div className="row EventsPageHeader">
        <div className="hidden-xs col-sm-1">
          <BtnExpander
            handleBlocksToggle={props.handleBlocksToggle}
            displayLadder={props.displayLadder}
          />
        </div>
        <div className="col-xs-10 col-sm-10">{props.title}</div>
        <div className="hidden-xs col-sm-1">
          <BtnSearch
            bordered={true}
            filterLadder={props.filterLadder}
            searchLadder={props.searchLadder}
          />
        </div>
        <BtnSearchMobile filterLadder={props.filterLadder} searchLadder={props.searchLadder} />
      </div>
    </PageHeader>
  );
}

export default EventsPageHeader;
