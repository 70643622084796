import React, { Component } from 'react';
import ReactHtmlParser from 'react-html-parser';
import { Link } from 'react-router-dom';

import DateBlock from '../components/DateBlock';
import TitleBlock from '../components/TitleBlock';
import EventDetail from '../components/EventDetail';
import Hero from '../components/Hero';
import Spinner from '../components/Spinner';
import SocialShare from '../components/SocialShare';
import HeaderTags from '../helpers/HeaderTags';
import BtnTicket from '../components/btn/ticket';
import DinnerReservation from '../components/DinnerReservation';
import Related from '../components/Related';
import { decoratedFetch } from '../helpers/fetch';
import MediaCarousel from '../components/MediaCarousel';
import CoronaCheck from '../components/CoronaCheck';
import wees from '../images/404_wees.png';

import '../css/event_page.css';
class EventPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      event: null,
      event_id: this.props.match.params.eventId,
      albums: [],
      related: [],
      preview: this.props.match.path === '/static/preview'
    };
  }

  componentDidMount() {
    this.retrieveEvent();
  }
  componentDidUpdate = () => {
    window.scrollTo(0, 0);
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.match.params.eventId !== this.state.event_id) {
      this.setState(
        {
          event: null,
          event_id: nextProps.match.params.eventId,
          albums: [],
          related: []
        },
        this.retrieveEvent
      );
    }
  };

  fakeEvent = options => {
    return Object.assign(
      {
        id: -1,
        main_image_sizes: {
          image_landing_big: null
        },
        ticket_url: null,
        fee_entrance: null,
        opens_at: null,
        starts_at: null,
        closes_at_iso: null,
        venue: null,
        age_limit: null,
        media: [],
        date: ' - - ',
        long_date: ' ',
        title: '',
        sub_title: '',
        description: '<p>Deze pagina bestaat niet! </p><a href="/" class="btn btn-default">Ga terug naar de homepage</a>',
        meta: {
          og: {
            image: {
              url: ""
            }
          }
        }
      },
      options
    );
  };

  increment = data => {
    console.log(data);
  };

  retrieveEvent = () => {
    // see if we're in preview context
    if (this.state.preview) {
      this.setState({
        event_id: -1,
        event: this.fakeEvent({
          title: '',
          sub_title: '',
          date: '-~-# #',
          long_date: '•'
        })
      });
    } else {
      decoratedFetch('/events/' + this.state.event_id + '.json')
        .then(response => response.json())
        .then(data => this.setState(data))
        .catch(e => {
          console.log('error: ', e.message);
          this.setState({
            event_id: -1,
            event: this.fakeEvent({
              title: e.message,
              sub_title: '',
              date: '-~-# #',
              long_date: '•',
              main_image: wees,
              main_image_sizes: {
                image_landing_big: wees
              },
            })
          });
        });
    }
  };

  render() {
    let event = this.state.event || this.fakeEvent({ title: 'Loading...' });
    let media = event.media
      .filter(function(obj) {
        return obj.type === 'link';
      })
      .map(function(b) {
        return '<li>' + b.path + '</li>';
      }).join('');
    let fee = [];
    let date = new Date(event.date);
    if (date > new Date()) {
      if (event.free_event) {
        fee.push(['entree', 'GRATIS']);
      } else {
        fee.push(['entree', event.fee_entrance]);
        if (event.fee_presale && event.fee_presale !== event.fee_entrance) {
          fee.push(['vvk', event.fee_presale]);
        }
      }
    } else {
      fee.push(['jaar', date.getFullYear()]);
    }

    return (
      <div id="EventPage" className={event.venue_color}>
        <Hero event={event} showTitle={false} fixed={true} />
        <div className="container-fluid">
          <div className="container EventPageContent">
            <article className="col-xs-12 col-sm-8">
              <div className="row no-gutter">
                <div className="col-xs-3">
                  <DateBlock event={event} />
                </div>
                <div className="col-xs-9 title">
                  <TitleBlock title={event.title} sub_title={event.sub_title} />
                </div>
              </div>
              {event ? (
                <div className="row no-gutter">
                  <div className="row EventPageDetails mb-3">
                    <EventDetail details={fee} />
                    <EventDetail
                      details={[['open', event.opens_at], ['aanvang', event.starts_at]]}
                    />
                    <EventDetail
                      details={[['locatie', event.venue], ['leeftijd', event.age_limit]]}
                    />
                  </div>
                  <div className="row visible-xs inverted-bg-color mb-3">
                    {event.type === 'Dinner' && <DinnerReservation event={event} />}
                    {event.type === 'Event' && (
                      <BtnTicket event={event} inline={true} noFly={true} />
                    )}
                    {event.venue === 'Burgerhaven' && (
                      <Link
                        to="/info/burgerhaven"
                        className={"col-xs-12 burgerhaven-logo"}
                      />
                    )}
                  </div>
                  {event.corona_check && <CoronaCheck className="visible-xs"/>}
                  {this.state.albums && <MediaCarousel albums={this.state.albums} />}
                  <div className="row EventPageBody">
                    <div className="col-xs-12">
                      {ReactHtmlParser(event.description, { decodeEntities: true })}
                    </div>
                  </div>
                  <div className="row EventPageFooter">
                    <EventDetail details={[['links', media]]} className="col-xs-12 col-sm-6" />
                  </div>
                </div>
              ) : (
                <Spinner />
              )}
            </article>
            <aside className="col-xs-12 col-sm-3">
              {event.venue === 'Burgerhaven' && (
                <Link
                  to="/info/burgerhaven"
                  className={"col-xs-12 burgerhaven-logo"}
                />
              )}
              {event.type === 'Dinner' && (
                <div className="col-xs-12 inverted-bg-color">
                  <DinnerReservation event={event} />
                </div>
              )}
              {event.type !== 'Dinner' && (
                <div style={{ width: '100%', display: 'inline-block' }}>
                  <div className="primary-bg-color">
                    <BtnTicket event={event} inline={true} blackBg={true} />
                  </div>
                  {event.fb_event_id && (
                    <div className="col-xs-12 text-center inverted-bg-color">
                      <br />
                      <a
                        href={'https://www.facebook.com/' + event.fb_event_id}
                        className="btn btn-default"
                        target="_blank"
                        rel="noreferrer noopener"
                      >
                        FACEBOOK EVENT
                      </a>
                      <br />
                      <br />
                    </div>
                  )}
                </div>
              )}
              <SocialShare snip_url={event.snip_url} snip_title={event.snip_title} />
              {event.corona_check && <CoronaCheck className="hidden-xs"/>}
            </aside>
          </div>
        </div>
        {event && (
          <div className="hidden vevent">
            <HeaderTags title={event.meta.title} />
            <a className="url summary" href={event.event_url}>
              {event.meta.description}
            </a>
            <span className="dtstart">
              {event.date}
              <div className="value-title" title={event.start} />
            </span>
            <img
              className="photo"
              src={event.meta.og.image.url}
              title={event.meta.title}
              alt={event.meta.title}
            />
            <span className="location">{event.venue}</span>
            <span className="category">{event.category}</span>
            <span className="tickets">
              <span className="hoffer">
                {event.ticket_url && <a className="offerurl" href={event.ticket_url} />}
                {event.free_event ? (
                  <span className="price">€ 0,-</span>
                ) : (
                  <span className="price">{event.fee_entrance}</span>
                )}
              </span>
            </span>
          </div>
        )}
        <Related related={this.state.related} />
      </div>
    );
  }
}

export default EventPage;
