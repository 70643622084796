import React, { Component } from 'react';
import DoubleCarouselItem from './DoubleCarouselItem';
import Spinner from './Spinner';
import { decoratedFetch } from './../helpers/fetch';
import '../css/double_carousel.css';

class DoubleCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carousel_events: props.carousel_events || []
    };
  }

  componentDidMount() {
    if (this.state.carousel_events.length === 0) {
      decoratedFetch('/events/confirmed.json')
        .then(response => response.json())
        .then(({ events: items }) => this.setState({ carousel_events: items }));
    }
  }

  render() {
    if (!this.state.carousel_events.length) {
      return (
        <div className="container DoubleCarousel">
          <Spinner />
        </div>
      );
    } else {
      let carousel_events = this.state.carousel_events;
      return (
        <div className="container DoubleCarousel">
          <div className="col-xs-2 hidden-xs hidden-sm" />
          <DoubleCarouselItem item={carousel_events[0]} pos="left" />
          <DoubleCarouselItem item={carousel_events[1]} pos="right" />
        </div>
      );
    }
  }
}
export default DoubleCarousel;
