import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { decoratedFetch } from '../helpers/fetch';
import '../css/content_blocks_bbc.css';

export default class ContentBlocksBBC extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pages: props.pages || []
    };
  }

  componentDidMount() {
    if (this.state.pages.length === 0) {
      decoratedFetch('/pages.json?shuffle=true&category=' + this.props.tag + '&limit=40')
        .then(response => response.json())
        .then(data => this.setState(data));
    }
  }

  render() {
    var childElements = this.state.pages.map((page, idx) => (
      <a
        href={page.body_sanitized}
        key={idx}
        target="_blank"
        className="ContentBlockBBC col-xs-6 col-md-3"
      >
        <div className="wrapper_1_1">
          <div
            className="ImageBlock"
            style={{ backgroundImage: 'url(' + page.main_image_sizes.image_big + ')' }}
          />
        </div>
        <div className="title">{page.title}</div>
      </a>
    ));
    return <div className="ContentBlocksBBC">{childElements}</div>;
  }
}
