import React from 'react';
import PropTypes from 'prop-types';
import '../css/date_block.css';

function DateBlock(props) {
  let event = props.event;
  let show_border = props.show_border || false;
  let class_names = ['DateBlock', props.className];
  if (typeof props.idx !== 'undefined') {
    class_names.push(props.idx % 2 ? 'DateBlockEven' : 'DateBlockOdd');
  }
  let date = new Date(event.date);
  let year = null;
  if (date < new Date()) {
    year = date.getFullYear();
  }
  return (
    <svg
      className={class_names.join(' ')}
      width="170px"
      height="170px"
      viewBox="0 0 170 170"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Symbols" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="DateBlock" transform="translate(0.000000, 5.000000)" fill="#FFFFFF">
          <text
            id="day"
            fontFamily="KlavikaBurgerBold, Klavika Burger, 'Helvetica Neue', Helvetica, Arial"
            fontSize="62"
            fontWeight="bold"
            textAnchor="middle"
            x="85"
            y="66"
            width="170"
          >
            {event.day_of_month}
          </text>
          <text
            id="day_of_week"
            fontFamily="KlavikaBurgerLight, Klavika Burger, 'Helvetica Neue', Helvetica, Arial"
            fontSize="24"
            fontWeight="300"
            textAnchor="middle"
            x="85"
            y="90"
            width="170"
          >
            {event.long_date.split(' ')[0]}
          </text>
          <text
            id="month"
            fontFamily="KlavikaBurgerLight, Klavika Burger, 'Helvetica Neue', Helvetica, Arial"
            fontSize="62"
            fontWeight="300"
            textAnchor="middle"
            x="85"
            y="138"
            width="170"
          >
            {event.date.split('-')[1]}
          </text>
          {year && (
            <text
              id="year"
              fontFamily="KlavikaBurgerLight, Klavika Burger, 'Helvetica Neue', Helvetica, Arial"
              fontSize="20"
              fontWeight="300"
              textAnchor="middle"
              x="85"
              y="160"
              width="170"
            >
              {year}
            </text>
          )}
        </g>
        {show_border && (
          <rect
            id="DateBlockBorder"
            stroke="#FFFFFF"
            strokeWidth="2"
            x="1"
            y="1"
            width="168"
            height="168"
          />
        )}
      </g>
    </svg>
  );
}

DateBlock.propTypes = {
  name: PropTypes.string,
  event: PropTypes.shape({
    date: PropTypes.string,
    longdate: PropTypes.number
  })
};
export default DateBlock;
