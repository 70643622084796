import React from 'react';
import { Carousel } from 'react-bootstrap';
import '../css/carousel.css';

function MediaCarousel(props) {
  let albums = props.albums;

  return (
    <div>
      {albums.map((album, idx) => (
        <figure className="MediaCarousel" key={idx}>
          <div className="embed-responsive embed-responsive-16by9 embed-responsive-2by1">
            <Carousel
              className="embed-responsive-item"
              nextIcon={<div className="Btn BtnCarousel BtnRight" />}
              prevIcon={<div className="Btn BtnCarousel BtnLeft" />}
            >
              {album.items &&
                album.items.map((item, idx) => (
                  <Carousel.Item key={idx}>
                    {item.type == 'image' && (
                      <img src={item.image.landing_big.url} alt={album.photographer_name} />
                    )}
                    {item.type == 'video' && (
                      <div className="video" dangerouslySetInnerHTML={{ __html: item.video }} />
                    )}
                  </Carousel.Item>
                ))}
            </Carousel>
          </div>
          <figcaption>Foto's: © {album.photographer_name}</figcaption>
        </figure>
      ))}
    </div>
  );
}
export default MediaCarousel;
