import React from 'react';
import DateBlock from './DateBlock';
import BtnTicket from './btn/ticket';
import EventLink from '../helpers/EventLink';
import { eventStateListValues } from './../helpers/eventStateListValues';

import '../css/ladder_row.css';
import '../css/burgerhaven.css';

function LadderRow(props) {
  let event = props.item;
  let albums = props.albums;
  let idx = props.idx;
  let divStyle = {
    backgroundImage: 'url(' + event.main_image_sizes.image_square + ')'
  };
  let box1 = (
    <div className="wrapper_1_1">
      <div className="ImageBlock" style={divStyle} />
    </div>
  );
  let box2 = (
    <div>
      <DateBlock event={event} idx={idx} />
    </div>
  );
  let ctaText = null;
  if (event.event_state_list && event.event_state_list.length > 0) {
    ctaText = eventStateListValues[event.event_state_list[0]];
  }

  return (
    <div className={"LadderRow " + event.venue_color}>
      <EventLink event={event}>
        <div className="row no-gutter">
          <div className="col-xs-2">{idx % 2 ? box1 : box2}</div>
          <div className="col-xs-2">{idx % 2 ? box2 : box1}</div>
          <div className="col-xs-8">
            <div className="wrapper_1_4">
              {ctaText && <span className={"visible-xs eventState eventState--" + event.event_state_list[0]}>{ctaText}</span>}
              <div className="hidden-xs">
                <BtnTicket event={event} right={true} />
              </div>
              <div className="Detail">
                <div
                  className="DetailHeader primary"
                  dangerouslySetInnerHTML={{ __html: event.title }}
                />
                <div
                  className="DetailHeader secondary"
                  dangerouslySetInnerHTML={{ __html: event.sub_title }}
                />
              </div>
              <div className="DetailDate hidden-xs">
                LOCATIE: <span style={{ color: "white" }}>{event.venue}</span>
                {' | '}
                OPEN: <span style={{ color: "white" }}>{event.opens_at} UUR</span>
                {event.fee_entrance && (
                  <span>
                    {' '}
                    | PRIJS: <span style={{ color: "white" }}>{event.fee_entrance}</span>{' '}
                    <small>
                      <small>(incl. servicekosten)</small>
                    </small>
                  </span>
                )}
                {event.free_event && <span> - GRATIS TOEGANG</span>}
              </div>
            </div>
          </div>
        </div>
      </EventLink>
    </div>
  );
}

export default LadderRow;
