import React from 'react';
import MetaTags from 'react-meta-tags';

export default function HeaderTags(props) {
  let item = props;
  let url = '';
  if (item.url) {
    url = item.url;
  } else if (item.path) {
    url = 'https://' + window.location.hostname + '/' + item.path;
  }
  return (
    <MetaTags>
      <title>{item.title} - Burgerweeshuis</title>
      <meta id="meta-og-title" property="og:title" content={item.title} />
      {item.description && (
        <meta id="meta-description" name="Description" content={item.description} />
      )}
      {item.description && (
        <meta id="meta-og-description" property="og:description" content={item.description} />
      )}
      {item.type && <meta id="meta-og-type" property="og:type" content={item.type} />}
      {item.image && <meta id="meta-og-image" property="og:image" content={item.image} />}
      {url && <meta id="meta-og-url" property="og:url" content={url} />}
    </MetaTags>
  );
}
