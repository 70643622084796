import React, { Component } from 'react';
import Hero from './Hero';
import Spinner from './Spinner';
import { Carousel } from 'react-bootstrap';
import { decoratedFetch } from './../helpers/fetch';
import '../css/heroes.css';

class Heroes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      highlites: props.highlites || []
    };
  }

  componentDidMount() {
    if (this.state.highlites.length === 0) {
      decoratedFetch('/events/highlites.json')
        .then(response => response.json())
        .then(({ events: items }) => this.setState({ highlites: items }));
    }
  }

  render() {
    let events = this.state.highlites;
    return (
      <Carousel id="heroes-carousel" interval={3000} className="container p-0">
        {events.length > 0 ? (
          events.map((event, idx) => (
            <Carousel.Item key={idx}>
              <Hero event={event} key={idx} showDash={true} />
            </Carousel.Item>
          ))
        ) : (
          <Spinner />
        )}
      </Carousel>
    );
  }
}

export default Heroes;
