import React, { Component } from 'react';
import '../../css/btn_search_mobile.css';

export default class BtnSearchMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: null,
      open: false,
      search_value: ''
    };
    this.textInput = React.createRef();
  }

  focusTextInput = () => {
    this.textInput.current && this.textInput.current.focus();
  };
  handleChange = e => {
    this.setState({ search_value: e.target.value }, this.searchLadder);
  };
  handleKeyPress = e => {
    if (e.key === 'Enter') {
      this.searchLadder();
    }
  };
  openSearch = () => {
    this.setState({ open: !this.state.open, search_value: '' }, () => {
      this.focusTextInput();
      this.searchLadder();
    });
  };
  searchLadder = () => {
    this.props.searchLadder(this.state.search_value);
  };

  render() {
    return (
      <div className="BtnSearchMobileWrapper">
        <div className="visible-xs col-xs-2">
          <button className="Btn BtnSearch" onClick={this.openSearch} />
        </div>
        {this.state.open && (
          <div className="visible-xs col-xs-12 BtnSearchInput">
            <input
              className="SearchForm"
              type="text"
              placeholder="Zoek"
              value={this.state.search_value}
              onChange={this.handleChange}
              onKeyPress={this.handleKeyPress}
              ref={this.textInput}
            />
          </div>
        )}
      </div>
    );
  }
}
