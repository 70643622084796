import React, { Component } from 'react';
import '../css/dinner_reservation.css';
import BtnDinnerReservation from './btn/BtnDinnerReservation';

export default class DinnerReservation extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    let event = this.props.event;
    return (
      <div className="DinnerReservation">
        <h3>Reserveer nu!</h3>
        <BtnDinnerReservation event={event} className="BtnMore" />
      </div>
    );
  }
}
