import React from 'react';
import '../../css/btn.css';

function BtnExpander(props) {
  let btnClass = ['Btn', 'BtnExpander', props.displayLadder ? 'displayLadder' : ''].join(' ');
  return (
    <div className={btnClass} onClick={props.handleBlocksToggle}>
      Expand
    </div>
  );
}

export default BtnExpander;
