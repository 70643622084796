import React, { Component } from 'react';
import { Waypoint } from 'react-waypoint';

import Hero from '../components/Hero';
import TitleBlock from '../components/TitleBlock';
import SocialShare from '../components/SocialShare';
import NewsItemTeaser from '../components/NewsItemTeaser';
import Spinner from '../components/Spinner';
import { decoratedFetch } from '../helpers/fetch';
import HeaderTags from '../helpers/HeaderTags';
import { titledId } from '../helpers/titledId';
import wees from '../images/404_wees.png';

import '../css/news_item_page.css';

export default class NewsItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      news_item_id: this.props.match.params.newsId,
      news_items: [],
      news_item: null
    };
    this.getNewsItem(this.state.news_item_id);
  }

  getNewsItem = news_item_id => {
    decoratedFetch('/news/' + news_item_id + '.json')
      .then(response => response.json())
      .then(({ news: item }) => this.setState({ news_item: item }))
      .catch(e => {
        console.log('error: ', e.message);
        this.setState({
          news_item_id: -1,
          news_item: this.fakeNews({
            // body: '<div class="text-center col-xs-12"><h2>' + e.message + '</h2></div>',
            body: '<p>Deze pagina bestaat niet! </p><a href="/" class="btn btn-primary">Ga terug naar de homepage</a>',
            title: e.message,
            main_image: wees,
            main_image_sizes: {
              image_landing_big: wees
            }
          })
        });
      });
  };

  getNewsItems = () => {
    decoratedFetch('/news.json')
      .then(response => response.json())
      .then(({ news: items }) => this.setState({ news_items: items }))
      .catch(e => {
        console.log('error: ', e.message);
        this.setState({
          news_items: [
            this.fakeNews({
              body: '<div class="text-center col-xs-12"><h2>' + e.message + '</h2></div>',
              title: e.message
            })
          ]
        });
      });
  };

  UNSAFE_componentWillReceiveProps = nextProps => {
    if (nextProps.match.params.newsId !== this.state.news_item_id) {
      this.setState(
        {
          news_item_id: nextProps.match.params.newsId,
          news_item: null
        },
        this.getNewsItem(nextProps.match.params.newsId)
      );
    }
  };

  fakeNews = options => {
    return Object.assign(
      {
        title: '',
        main_image_sizes: {
          image_landing_big: null
        },
        teaser: '',
        body: ''
      },
      options
    );
  };

  render() {
    let news_item = this.state.news_item || this.fakeNews({ body: 'Loading' });
    let news_items = this.state.news_items || [];
    return (
      <div id="NewsItemPage">
        <Hero event={news_item} />
        <div className="container-fluid NewsItemPageContent">
          <div className="container">
            <article className="col-xs-12 col-sm-8 inverted-bg-color">
              {news_item ? (
                <div>
                  <TitleBlock title={news_item.title} />
                  <div className="row no-gutter date">{news_item.long_date}</div>
                </div>
              ) : (
                <Spinner />
              )}
              {news_item && (
                <div
                  className="row no-gutter"
                  dangerouslySetInnerHTML={{ __html: news_item.body }}
                />
              )}
              <Waypoint onEnter={this.getNewsItems}>
                <div className="container-fluid">
                  <div className="PageHeader">MEER NIEUWS</div>
                  {news_items
                    .slice(
                      0,
                      this.state.expanded ? this.state.news_items.length : this.state.loadAmount
                    )
                    .map((item, idx) => (
                      <NewsItemTeaser
                        item={item}
                        key={item.id}
                        idx={idx}
                        selected={item.id === parseInt(this.state.news_item_id, 10)}
                      />
                    ))}
                </div>
              </Waypoint>
            </article>
            <aside className="col-xs-12 col-sm-3">
              <SocialShare snip_url={news_item.snip_url} snip_title={news_item.snip_title} />
            </aside>
          </div>
        </div>
        <HeaderTags title={news_item.title} />
      </div>
    );
  }
}
