import React from 'react';
import { Link } from 'react-router-dom';
import '../../css/btn.css';

export default function BtnMore(props) {
  return (
    <Link to={props.to} className="BtnMore">
      LEES MEER
    </Link>
  );
}
