import React from 'react';
import corona_check_img from '../images/corona_check.png';
import '../css/corona_check.css';

function CoronaCheck(props) {
  return (
    <div className={"corona-check mb-3 " + props.className}>
      <h4>
        <img
          src={corona_check_img}
          alt="Bij dit event is een corona check verplicht."
        />
        LET OP!
      </h4>
      <p>
        Voor deze show is de CoronaCheck app verplicht,
        zorg voor een geldige QR-code en vergeet je ID niet!
      </p>
    </div>
  );
}

export default CoronaCheck;
