import React from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton } from 'react-share';
import Clipboard from './Clipboard';
import '../css/social_share.css';

const SocialShare = props => {
  var snip_url = props.snip_url;
  var snip_title = props.snip_title;
  if (snip_url) {
    return (
      <div className="SocialShare">
        <FacebookShareButton url={snip_url} quote={snip_title} className="SocialMediaShareButton">
          <div className="Btn BtnShareFacebook" alt="Deel via Facebook" title="Deel via Facebook" />
        </FacebookShareButton>
        <Clipboard text={snip_url} />
        <TwitterShareButton url={snip_url} title={snip_title} className="SocialMediaShareButton">
          <div className="Btn BtnShareTwitter" alt="Deel via Twitter" title="Deel via Twitter" />
        </TwitterShareButton>
        <WhatsappShareButton url={snip_url} title={snip_title} className="SocialMediaShareButton">
          <div className="Btn BtnShareWhatsapp" alt="Deel via Whatsapp" title="Deel via Whatsapp" />
        </WhatsappShareButton>
      </div>
    );
  } else {
    return <div className={'SocialShare '} />;
  }
};
export default SocialShare;
